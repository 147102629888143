<template>
  <div>
    <div class="modal_header">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24">
          <div class="grid-content bg-purple">
            <div class="title_h">
              {{ $t("message.update_patient_indicators") }}
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24">
          <div class="grid-content bg-purple-light">
            <el-row class="button_r">
              <el-button
                type="success"
                :loading="loadingButton"
                :disabled="loadingButton ? true : false"
                @click="submit(true)"
                >{{ $t("message.save_and_close") }}</el-button
              >
              <el-button @click="close()">{{ $t("message.close") }}</el-button>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="center__block_form">
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        class="aticler_m"
        size="small"
      >
        <el-row :gutter="25">
          <el-col :sm="8">
            <el-form-item :label="$t('message.name_0')" prop="name">
              <el-input v-model="form.name" :max="80"/>
            </el-form-item>
          </el-col>
          <el-col :sm="8">
            <el-form-item :label="$t('message.department')" prop="department">
              <el-select
                v-model="form.branch_id"
                :placeholder="$t('message.department')"
              >
                <el-option
                  v-for="item in departments"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :sm="8">
            <el-form-item :label="$t('message.price')" prop="price">
              <el-input type="number" :max="80" v-model="form.price"/>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :sm="24">
            <el-form-item :label="$t('message.description')" prop="description">
              <el-input type="textarea" :rows="6" v-model="form.description"/>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :sm="24">
            <el-tabs type="border-card">
              <el-tab-pane :label="$t('message.service')">
                <div class="d-flex py-4">
                  <el-select
                    v-model="patientServiceId"
                    filterable
                    :placeholder="$t('message.start_input_find_service')"
                    class="autoInput"
                    clearable
                  >
                    <template v-for="serviceParent in servicesSelected">
                      <el-option-group
                        v-if="
                          serviceParent.children &&
                          serviceParent.children.length > 0
                        "
                        :key="serviceParent.id"
                        :label="serviceParent.name"
                      >
                        <el-option
                          v-for="service in getChildrens(
                            serviceParent.children
                          )"
                          :key="service.id"
                          :label="service.name"
                          :value="service.id"
                        />
                      </el-option-group>
                      <el-option-group
                        v-else-if="
                          serviceParent.is_service == true &&
                          serviceParent.parent_id == null
                        "
                        :key="serviceParent.id"
                      >
                        <el-option
                          :label="serviceParent.name"
                          :value="serviceParent.id"
                        />
                      </el-option-group>
                    </template>
                  </el-select>
                  <el-button
                    style="
                      font-size: 14px !important;
                      padding: 12px !important;
                      margin-left: 10px;
                    "
                    @click="addService"
                    >{{ $t("message.add") }}</el-button
                  >
                  <el-button
                    style="font-size: 14px !important; padding: 12px !important"
                    @click="openServices('service_items')"
                    >{{ $t("message.add_service") }}</el-button
                  >
                </div>

                <table class="table table-striped table-bordered mt-4">
                  <thead>
                    <tr>
                      <th class="wi-50">{{ $t("message.name") }}</th>
                      <th class="wi-50">{{ $t("message.quantity_short") }}</th>
                      <th class="wi-7">{{ $t("message.delete") }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(ser, index) in service_items"
                      :key="'order-create-in-header' + index"
                    >
                      <td>
                        {{ ser.name }}
                      </td>
                      <td>
                        <el-input-number
                          v-model="ser.count"
                          style="margin-right: 10px"
                          controls-position="right"
                          :min="1"
                        ></el-input-number>
                      </td>
                      <td>
                        <el-button
                          type="danger"
                          style="display: inline"
                          controls-position="right"
                          icon="el-icon-delete"
                          circle
                          @click.native.prevent="deleteServiceRow(ser)"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </el-tab-pane>
              <el-tab-pane :label="$t('message.physiotherapy')">
                <div class="d-flex py-4">
                  <el-select
                    v-model="patientPhysiotherapyId"
                    filterable
                    :placeholder="$t('message.start_input_find_service')"
                    class="autoInput"
                    clearable
                  >
                    <template v-for="serviceParent in servicesSelected">
                      <el-option-group
                        v-if="
                          serviceParent.children &&
                          serviceParent.children.length > 0
                        "
                        :key="serviceParent.id"
                        :label="serviceParent.name"
                      >
                        <el-option
                          v-for="service in getChildrens(
                            serviceParent.children
                          )"
                          :key="service.id"
                          :label="service.name"
                          :value="service.id"
                        />
                      </el-option-group>
                      <el-option-group
                        v-else-if="
                          serviceParent.is_service == true &&
                          serviceParent.parent_id == null
                        "
                        :key="serviceParent.id"
                      >
                        <el-option
                          :label="serviceParent.name"
                          :value="serviceParent.id"
                        />
                      </el-option-group>
                    </template>
                  </el-select>
                  <el-button
                    style="
                      font-size: 14px !important;
                      padding: 12px !important;
                      margin-left: 10px;
                    "
                    @click="addPhysiotherapy"
                    >{{ $t("message.add") }}</el-button
                  >
                  <el-button
                    style="font-size: 14px !important; padding: 12px !important"
                    @click="openServices('service_physiotherapy_items')"
                    >{{ $t("message.add_service") }}</el-button
                  >
                </div>

                <table class="table table-striped table-bordered mt-4">
                  <thead>
                    <tr>
                      <th class="wi-50">{{ $t("message.name") }}</th>
                      <!-- <th class="wi-50">{{ $t("message.quantity_short") }}</th> -->
                      <th class="wi-7">{{ $t("message.delete") }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(ser, index) in service_physiotherapy_items"
                      :key="'order-create-in-header' + index"
                    >
                      <td>
                        {{ ser.name }}
                      </td>
                      <!-- <td>
                        <el-input-number
                          v-model="ser.count"
                          style="margin-right: 10px"
                          controls-position="right"
                          :min="1"
                        ></el-input-number>
                      </td> -->
                      <td>
                        <el-button
                          type="danger"
                          style="display: inline"
                          controls-position="right"
                          icon="el-icon-delete"
                          circle
                          @click.native.prevent="deletePhysiotherapyRow(ser)"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </el-tab-pane>
              <el-tab-pane :label="$t('message.laboratory')">
                <div class="d-flex py-4">
                  <el-select
                    v-model="patientLaboratoryServiceId"
                    filterable
                    :placeholder="$t('message.start_input_find_service')"
                    class="autoInput"
                    clearable
                  >
                    <template v-for="serviceParent in servicesSelected">
                      <el-option-group
                        v-if="
                          serviceParent.children &&
                          serviceParent.children.length > 0
                        "
                        :key="serviceParent.id"
                        :label="serviceParent.name"
                      >
                        <el-option
                          v-for="service in getChildrens(
                            serviceParent.children
                          )"
                          :key="service.id"
                          :label="service.name"
                          :value="service.id"
                        />
                      </el-option-group>
                      <el-option-group
                        v-else-if="
                          serviceParent.is_service == true &&
                          serviceParent.parent_id == null
                        "
                        :key="serviceParent.id"
                      >
                        <el-option
                          :label="serviceParent.name"
                          :value="serviceParent.id"
                        />
                      </el-option-group>
                    </template>
                  </el-select>
                  <el-button
                    style="
                      font-size: 14px !important;
                      padding: 12px !important;
                      margin-left: 10px;
                    "
                    @click="addLaboratoryService"
                    >{{ $t("message.add") }}</el-button
                  >
                  <el-button
                    style="font-size: 14px !important; padding: 12px !important"
                    @click="openServices('service_laboratory_items')"
                    >{{ $t("message.add_service") }}</el-button
                  >
                </div>

                <table class="table table-striped table-bordered mt-4">
                  <thead>
                    <tr>
                      <th class="wi-50">{{ $t("message.name") }}</th>
                      <th class="wi-50">{{ $t("message.quantity_short") }}</th>
                      <th class="wi-7">{{ $t("message.delete") }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(ser, index) in service_laboratory_items"
                      :key="'order-create-in-header' + index"
                    >
                      <td>
                        {{ ser.name }}
                      </td>
                      <td>
                        <el-input-number
                          v-model="ser.count"
                          style="margin-right: 10px"
                          controls-position="right"
                          :min="1"
                        ></el-input-number>
                      </td>
                      <td>
                        <el-button
                          type="danger"
                          style="display: inline"
                          controls-position="right"
                          icon="el-icon-delete"
                          circle
                          @click.native.prevent="
                            deleteLaboratoryServiceRow(ser)
                          "
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </el-tab-pane>
              <el-tab-pane :label="$t('message.valid')">
                <el-col :span="8">
                  <el-form-item
                    :label="$t('message.show_valid_price')"
                    prop="pocket_treatments_price_limit"
                  >
                    <el-input
                      type="number"
                      v-model="form.pocket_treatment.price"
                    />
                  </el-form-item>
                </el-col>
              </el-tab-pane>
              <el-tab-pane :label="$t('message.rooms')">
                <div class="d-flex py-4">
                  <el-select
                    v-model="patientRoom"
                    filterable
                    :placeholder="$t('message.start_input_find_service')"
                    class="autoInput"
                    clearable
                  >
                    <el-option
                      v-for="room in aviableRooms(hospitalRooms)"
                      :key="room.id"
                      :label="
                        room.corps.name +
                        '-' +
                        $t('message.corps') +
                        ' ' +
                        room.floor.name +
                        '-' +
                        $t('message.floor') +
                        ' ' +
                        room.name +
                        '-' +
                        $t('message.room')
                      "
                      :value="room.id"
                    />
                  </el-select>
                  <el-button
                    style="
                      font-size: 14px !important;
                      padding: 12px !important;
                      margin-left: 10px;
                    "
                    @click="addRoom"
                    >{{ $t("message.add") }}</el-button
                  >
                </div>

                <table class="table table-striped table-bordered mt-4">
                  <thead>
                    <tr>
                      <th class="wi-50">{{ $t("message.name") }}</th>
                      <th class="wi-50">
                        {{ $t("message.price") }}
                      </th>
                      <th class="wi-7">{{ $t("message.delete") }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(ser, index) in room_items"
                      :key="'order-create-in-header' + index"
                    >
                      <td>
                        {{ ser.name }}
                      </td>
                      <td>
                        <el-input-number
                          v-model="ser.price"
                          style="margin-right: 10px"
                          controls-position="right"
                          :min="1"
                        />
                      </td>
                      <td>
                        <el-button
                          type="danger"
                          style="display: inline"
                          controls-position="right"
                          icon="el-icon-delete"
                          circle
                          @click.native.prevent="deleteRoomRow(ser)"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </el-tab-pane>
            </el-tabs>
          </el-col>
        </el-row>
        <!-- end el-row -->
      </el-form>
      <el-drawer
        class="popups_title"
        :wrapperClosable="false"
        :append-to-body="true"
        :visible.sync="drawerPatientService"
        direction="rtl"
        size="85%"
        :ref="serviceDrawer.create.name"
        @opened="drawerOpened(serviceDrawer.create.component)"
        @closed="drawerClosed(serviceDrawer.create.component)"
      >
        <CreatePatientService
          :ref="serviceDrawer.create.component"
          :driver="drawerPatientService"
          :string="string"
          @items="itemsChanged"
          @c-close="closeDrawerPatientService"
        />
      </el-drawer>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import form from "@/utils/mixins/form";
import drawerChild from "@/utils/mixins/drawer-child";
import drawer from "@/utils/mixins/drawer";
import { i18n } from "@/utils/i18n";
import CreatePatientService from "./pocket_service";
export default {
  mixins: [form, drawer, drawerChild],
  props: ["selected"],
  components: {
    CreatePatientService,
  },
  data() {
    return {
      drawerPatientService: false,
      drawerService: false,
      departmentArray: [],
      loadingData: false,
      service_items: [],
      service_laboratory_items: [],
      service_physiotherapy_items: [],
      room_items: [],
      patientRoom: "",
      patientServiceId: "",
      patientLaboratoryServiceId: "",
      patientPhysiotherapyId: "",
      serviceDrawer: {
        create: {
          name: "create",
          status: false,
          component: "componentDrawerCreate",
        },
      },
      string: "",
    };
  },
  computed: {
    ...mapGetters({
      rules: "pocketsName/rules",
      hospitalRooms: "hospitalRooms/list",
      servicesSelected: "services/selected_relation_list",
      departments: "department/list",
      model: "pocketsName/model",
      columns: "pocketsName/columns",
    }),
  },
  methods: {
    ...mapActions({
      loadDepartment: "department/index",
      getInventory: "hospitalRooms/index",
      servicesSelectedRealtionList: "services/selectedRelationList",
      save: "pocketsName/update",
      editModel: "pocketsName/show",
      empty: "pocketsName/empty",
    }),
    closeDrawerPatientService(obj) {
      if (obj.drawer) {
        this[obj.drawer] = false;
      }
    },
    afterOpened() {
      if (this.selected && !this.loadingData) {
        this.loadingData = true;
        if (this.hospitalRooms && this.hospitalRooms.length === 0) {
          this.getInventory();
        }
        if (this.departments && this.departments.length === 0) {
          this.loadDepartment();
        }
        if (this.servicesSelected && this.servicesSelected.length === 0)
          this.servicesSelectedRealtionList({
            selected_relation: true,
          })
            .then((res) => {
              this.loadingData = false;
            })
            .catch((err) => {
              this.loadingData = false;
            });
        this.editModel(this.selected.id)
          .then(async (res) => {
            this.loadingData = false;
            this.service_items = [];
            this.service_laboratory_items = [];
            this.service_physiotherapy_items = [];
            this.room_items = [];
            this.form = JSON.parse(JSON.stringify(this.model));
            this.model.pocket_services.forEach((element) => {
              this.service_items.push({
                service_id: element.service_id,
                name: element.service ? element.service.name : "",
                count: element.count,
              });
            });
            this.model.pocket_physiotherapies.forEach((element) => {
              this.service_physiotherapy_items.push({
                service_id: element.service_id,
                name: element.service ? element.service.name : "",
              });
            });
            this.model.pocket_laboratory_services.forEach((element) => {
              this.service_laboratory_items.push({
                service_id: element.service_id,
                name: element.service ? element.service.name : "",
                count: element.count,
              });
            });
            this.model.pocket_rooms.forEach((element) => {
              this.room_items.push({
                room_id: element.room_id,
                name: element.room
                  ? element.room.corps.name +
                    "-" +
                    i18n.t("message.corps") +
                    " " +
                    element.room.floor.name +
                    "-" +
                    i18n.t("message.floor") +
                    " " +
                    element.room.name +
                    "-" +
                    i18n.t("message.room")
                  : "",
                price: element.price,
              });
            });
          })
          .catch((err) => {
            this.loadingData = false;
            this.$notify({
              title: "Ошибка",
              type: "error",
              offset: 130,
              message: err.error.message,
            });
          });
      }
    },
    getChildrens: function (children) {
      if (children)
        return children.filter((item) => {
          return item.is_service == true;
        });
      return [];
    },
    addService() {
      let vm = this;
      let service = this.servicesSelected.find(function (item) {
        return item.id == vm.patientServiceId;
      });
      if (
        service &&
        !this.service_items.find(function (item) {
          return item.service_id == vm.patientServiceId;
        })
      ) {
        this.service_items.push({
          service_id: service.id,
          name: service.name,
          count: 1,
        });
        this.patientServiceId = "";
      }
    },
    addLaboratoryService() {
      let vm = this;
      let service = this.servicesSelected.find(function (item) {
        return item.id == vm.patientLaboratoryServiceId;
      });
      if (
        service &&
        !this.service_laboratory_items.find(function (item) {
          return item.service_id == vm.patientLaboratoryServiceId;
        })
      ) {
        this.service_laboratory_items.push({
          service_id: service.id,
          name: service.name,
          count: 1,
        });
        this.patientLaboratoryServiceId = "";
      }
    },
    addPhysiotherapy() {
      let vm = this;
      let service = this.servicesSelected.find(function (item) {
        return item.id == vm.patientPhysiotherapyId;
      });
      if (
        service &&
        !this.service_physiotherapy_items.find(function (item) {
          return item.service_id == vm.patientPhysiotherapyId;
        })
      ) {
        this.service_physiotherapy_items.push({
          service_id: service.id,
          name: service.name,
          count: 1,
        });
        this.patientPhysiotherapyId = "";
      }
    },
    aviableRooms(rooms) {
      return rooms;
    },
    addRoom() {
      let vm = this;
      let room = this.hospitalRooms.find(function (item) {
        return item.id == vm.patientRoom;
      });
      if (
        room &&
        !this.room_items.find(function (item) {
          return item.room_id == vm.patientRoom;
        })
      ) {
        this.room_items.push({
          room_id: room.id,
          name:
            room.corps.name +
            "-" +
            i18n.t("message.corps") +
            " " +
            room.floor.name +
            "-" +
            i18n.t("message.floor") +
            " " +
            room.name +
            "-" +
            i18n.t("message.room"),
          price: 0,
        });
        this.patientRoom = "";
      }
    },
    deleteServiceRow(ser) {
      const index = this.service_items.indexOf(ser);
      this.service_items.splice(index, 1);
    },
    deleteLaboratoryServiceRow(ser) {
      const index = this.service_laboratory_items.indexOf(ser);
      this.service_laboratory_items.splice(index, 1);
    },
    deletePhysiotherapyRow(ser) {
      const index = this.service_physiotherapy_items.indexOf(ser);
      this.service_physiotherapy_items.splice(index, 1);
    },
    deleteRoomRow(ser) {
      const index = this.room_items.indexOf(ser);
      this.room_items.splice(index, 1);
    },
    afterClosed() {
      this.empty();
    },
    loadModel() {
      this.form = JSON.parse(JSON.stringify(this.model));
    },
    itemsChanged(val) {
      val.items.forEach((element) => {
        if (
          !this[val.string].find(function (item) {
            return item.service_id == element.id;
          })
        ) {
          this[val.string].push({
            service_id: element.id,
            name: element.name,
            count: 1,
          });
        }
      });
    },
    submit(close = true) {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.loadingButton = true;

          this.form.pocket_services = this.service_items;
          this.form.pocket_laboratory_services = this.service_laboratory_items;
          this.form.pocket_physiotherapies = this.service_physiotherapy_items;
          this.form.pocket_rooms = this.room_items;
          this.form.pocket_treatments_price_limit = this.form.pocket_treatment.price;

          this.save(this.form)
            .then((res) => {
              this.loadingButton = false;
              this.$alert(res.message);
              
              // this.$notify({
              //   title: "Успешно",
              //   type: "success",
              //   offset: 130,
              //   message: res.message,
              // });
              this.parent().listChanged();
              if (close == true) {
                this.close();
              }
            })
            .catch((err) => {
              this.loadingButton = false;
              this.$notify({
                title: "Ошибка",
                type: "error",
                offset: 130,
                message: err.error.message,
              });
            });
        }
      });
    },
  },
};
</script>
